import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { AuthError, LoginPage, useAuth } from "services/authentication";

//  Components.
import { LogoCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications/notification";
import { UserCredential } from "firebase/auth";
import { BevelButton } from "components/inputs";
import { WebAppLogin, useAnalytics } from "@neurosolutionsgroup/analytics";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";

const Login = (): JSX.Element => {
  const urlParams = useLocation().search;
  const params = queryString.parse(urlParams);
  const history = useHistory();

  const [success, setSuccess] = useState<boolean>(false);
  const [initialEmail, setInitialEmail] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(["webappAuthentication", "webappBase"]);

  const {
    actions: { isAuthenticated },
  } = useAuth();

  const { handleEvent } = useAnalytics();
  const { getRemoteConfigValue } = useRemoteConfig();

  useEffect(() => {
    if (params.email) {
      const email = params.email.toString();

      console.log(email);

      setInitialEmail(email);
    } else {
      setInitialEmail("");
    }
  }, []);

  const onSuccess = (response: UserCredential, email: string) => {
    const event: WebAppLogin = {
      name: "Web App Login",
    };

    handleEvent(event);

    setSuccess(true);
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("webappBase:forms.errors.title"),
        t("webappBase:forms.errors.general") +
          ": " +
          t("webappBase:forms.errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      {isAuthenticated() ? (
        <Redirect to={{ pathname: "/app", search: urlParams }} />
      ) : null}

      <LogoCard className="bg-beige">
        {initialEmail !== undefined ? (
          <LoginPage
            autoCheckout={params.autoCheckout ? true : false}
            additionnalTitle={
              params.source === "pinreset"
                ? t("pages.login.sources.pinreset.message")
                : undefined
            }
            onSuccess={onSuccess}
            onFailure={onFailure}
            passwordLink={"/account/forgottenpassword"}
            allowThirdParty={
              getRemoteConfigValue(ConfigString.ThirdPartyAuth).asString() ===
              "true"
            }
            email={initialEmail}
          />
        ) : null}

        <p>{t("pages.login.noAccount")}</p>

        <BevelButton
          className="btn btn-primary-alt w-100"
          onClick={() =>
            history.push({ pathname: "/account", search: urlParams })
          }
        >
          {t("pages.login.create")}
        </BevelButton>
      </LogoCard>

      {success ? (
        <div>
          {params.source === "pinreset" ? (
            <Redirect to={{ pathname: "/app/settings", search: urlParams }} />
          ) : params.source === "clinicaltrial" ? (
            <Redirect to={{ pathname: "/app/promocode", search: urlParams }} />
          ) : (
            <Redirect to={{ pathname: "/app/dashboard", search: urlParams }} />
          )}
        </div>
      ) : null}
    </CardPage>
  );
};

export default Login;
