import { Language } from "@neurosolutionsgroup/tools";
import { useRemoteConfigContext } from "./remoteConfigContext";
import { Value } from "firebase/remote-config";
import semverValid from "semver/functions/valid";
import semverGte from "semver/functions/gte";

interface UseRemoteConfigResult {
  checkFeatureFlagVersion: (key: string, version?: string) => boolean;
  getActivatedLanguages: () => Language[];
  getNumberConfig: (key: ConfigNumber) => number;
  getRemoteConfigValue: (key: string) => Value;
}

export enum FeatureFlag {
  LanguageEN = "language_en",
  LanguageFR = "language_fr",
  LanguageDE = "language_de",
  Challenges = "feature_flag_challenges",
  CustomerIo = "feature_flag_customerIo",
  SessionTracking = "feature_flag_session_tracking",
}

export enum WebviewsFeatureFlag {
  AbTestPaywall = "webviews_feature_flag_ab_test_paywall",
  ChildAnalytics = "webviews_feature_flag_child_analytics",
  DashboardExport = "webviews_feature_flag_dashboard_export",
  DefaultChildCreation = "webviews_feature_flag_default_child_creation",
  GenericLinks = "webviews_feature_flag_generic_links",
  LocalizedGuideLinks = "webviews_feature_flag_localized_guide_links",
  MedicalGuide = "webviews_feature_flag_medical_guide",
  MedicalShare = "webviews_feature_flag_medical_share",
  NativeThirdPartyAuth = "webviews_feature_flag_native_third_party_auth",
  PaywallMonthlyTrial = "webviews_feature_flag_paywall_monthly_trial",
  SoundSettingsRemoved = "webviews_feature_flag_sound_settings_removed",
  DeleteChildData = "webviews_feature_flag_delete_child_data",
  ChildGroup = "webviews_feature_flag_child_group",
  ChatBot = "webviews_feature_flag_chatbot",
  FollowUpDataChangeWarning = "webviews_feature_flag_follow_up_data_change_warning",
  FollowUpDataExport = "webviews_feature_flag_follow_up_data_export",
  ShorterFTUE = "webviews_feature_flag_shorter_ftue",
}

export enum ConfigString {
  AppLink = "config_link_app",
  ForgottenPasswordLink = "config_link_forgotten_password",
  PrivacyDE = "config_link_privacy_de",
  PrivacyEN = "config_link_privacy_en",
  PrivacyFR = "config_link_privacy_fr",
  SupportLink = "config_link_support",
  SupportLinkDE = "config_link_support_de",
  TermsDE = "config_link_terms_de",
  TermsEN = "config_link_terms_en",
  TermsFR = "config_link_terms_fr",
  WebAppSettings = "config_link_webapp_settings",
  ThirdPartyAuth = "config_third_party_auth",
}

export enum ConfigNumber {
  AbTestChatbotBPercentage = "config_ab_test_chatbot_b_percentage",
  AbTestPaywallBPercentage = "config_ab_test_paywall_b_percentage",
  ChildLimit = "config_child_limit",
  LogoutTimerlength = "config_logout_timer_length_parent",
  LogoutTimerLengthAdminDashboard = "config_admin_dashboard_logout_timer",
}

const useRemoteConfig = (): UseRemoteConfigResult => {
  const { getRemoteConfigValue } = useRemoteConfigContext();

  const getNumberConfig = (key: ConfigNumber): number => {
    return getRemoteConfigValue(key).asNumber();
  };

  const checkFeatureFlagVersion = (key: string, version?: string): boolean => {
    const value = getRemoteConfigValue(key).asString();

    if (!value || value === "false") {
      return false;
    }

    if (value === "true") {
      return true;
    }

    if (version && semverValid(value)) {
      return semverGte(version, value);
    }

    return false;
  };

  const getActivatedLanguages = (): Language[] => {
    const languages: Language[] = [];

    if (getRemoteConfigValue(FeatureFlag.LanguageEN).asString() !== "false") {
      languages.push("en");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageFR).asString() !== "false") {
      languages.push("fr");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageDE).asString() !== "false") {
      languages.push("de");
    }

    return languages;
  };

  return {
    checkFeatureFlagVersion,
    getActivatedLanguages,
    getNumberConfig,
    getRemoteConfigValue,
  };
};

export default useRemoteConfig;
