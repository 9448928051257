import React, { useEffect, useState } from "react";
import LoginPageComponent from "./LoginPageComponent";
import AuthError from "../commons/AuthError/AuthError";
import useLogin from "../hooks/useLogin/useLogin";
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  UserCredential,
} from "firebase/auth";
import { useTranslation } from "react-i18next";

interface Props {
  autoCheckout?: boolean;
  additionnalTitle?: string;
  onSuccess: (reponse: UserCredential, email: string) => void;
  onFailure: (err: AuthError) => void;
  passwordLink?: string;
  allowThirdParty?: boolean;
  email?: string;
}

const LoginPage = ({
  autoCheckout,
  additionnalTitle,
  onSuccess,
  onFailure,
  passwordLink,
  allowThirdParty,
  email,
}: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    actions: { loginByEmail },
  } = useLogin();

  useEffect(() => {
    const auth = getAuth();

    getRedirectResult(auth)
      .then((result) => {
        if (!result) {
          setIsLoading(false);
        } else {
          onSuccess(result, result.user.email ?? "");
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const executeCaptchaAsync = async (email: string, password: string) => {
    setIsLoading(true);

    const data = {
      email: email,
      password: password,
    };

    //  Check token returned and dispatch request to server.
    loginByEmail(data)
      .then((response) => {
        onSuccess(response, email);
      })
      .catch((err: AuthError) => {
        onFailure(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onLoginGoogle = () => {
    setIsLoading(true);

    const provider = new GoogleAuthProvider();

    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

    const auth = getAuth();

    auth.languageCode = i18n.language;

    signInWithRedirect(auth, provider);
  };

  const onLoginApple = () => {
    setIsLoading(true);

    const provider = new OAuthProvider("apple.com");

    provider.addScope("email");
    provider.addScope("name");

    const auth = getAuth();

    provider.setCustomParameters({
      locale: i18n.language,
    });

    signInWithRedirect(auth, provider);
  };

  return (
    <LoginPageComponent
      isLoading={isLoading}
      autoCheckout={autoCheckout}
      additionnalTitle={additionnalTitle}
      onSubmit={executeCaptchaAsync}
      onLoginGoogle={allowThirdParty ? onLoginGoogle : undefined}
      onLoginApple={allowThirdParty ? onLoginApple : undefined}
      passwordLink={passwordLink}
      email={email}
    />
  );
};

export default LoginPage;
